<template>
	<div class="suppierDetail">
		<el-form
			:model="ruleForm"
			ref="ruleForm"
			label-width="150px"
			style="margin-bottom:80px;"
			v-loading="loading"
		>
			<el-card class="box-card">
				<div slot="header" class="clearfix" v-if="ruleForm.authenticationType == 1">
					<el-steps space="30%" :active="active">
                        <el-step title="认证"></el-step>
                        <el-step title="绑卡"></el-step>
                        <el-step title="完成"></el-step>
                    </el-steps>
				</div>
				<div class="content">
                    <el-form-item label="认证类别：" v-if="active == 0">
                        <el-radio v-model="ruleForm.authenticationType" :label="1">个人</el-radio>
                        <el-radio v-model="ruleForm.authenticationType" :label="2">企业</el-radio>
                    </el-form-item>
                    <div v-if="ruleForm.authenticationType == 1">
                        <div v-if="active == 0">
                            <el-form-item 
                            label="真实姓名：" 
                                prop="realName"
                                :rules="[{required:true,message:'真实姓名不能为空',trigger: 'blur'}]">
                                <el-input v-model="ruleForm.realName" placeholder="请输入真实姓名" style="width:300px;"></el-input>
                            </el-form-item>
                            <el-form-item 
                                label="手机号：" 
                                prop="phone"
                                :rules="[{required:true,message:'手机号不能为空',trigger: 'blur'},]">
                                <el-input v-model="ruleForm.phone" placeholder="请输入手机号" style="width:300px;"></el-input>
                            </el-form-item>
                            <el-form-item 
                                label="验证码：" 
                                prop="verificationCodeType"
                                :rules="[{required:true,message:'验证码不能为空',trigger: 'blur'}]">
                                <el-input v-model="ruleForm.verificationCodeType" placeholder="请输入验证码" style="width:300px;"></el-input>
                                <el-button type="primary" style="margin-left:10px;" @click="send(3)" :disabled='countDown != 60'>{{countDownText}}</el-button>
                            </el-form-item>
                            <el-form-item 
                                label="身份证号码：" 
                                prop="identityNo"
                                :rules="[{required:true,message:'身份证号码不能为空',trigger: 'blur'}]">
                                <el-input v-model="ruleForm.identityNo" placeholder="请输入身份证号码" style="width:300px;"></el-input>
                            </el-form-item>
                        </div>
                        <div v-if="active == 1">
                            <el-form-item 
                                label="开户行：" 
                                prop="parentBankName"
                                :rules="[{required:true,message:'开户行不能为空',trigger: 'blur'}]">
                                <el-input v-model="ruleForm.parentBankName" placeholder="请输入开户行" style="width:500px;"></el-input>
                            </el-form-item>
                            <el-form-item 
                                label="开户支行：" 
                                prop="bankName"
                                :rules="[{required:true,message:'开户支行不能为空',trigger: 'blur'}]">
                                <el-input v-model="ruleForm.bankName" placeholder="请输入开户支行" style="width:500px;"></el-input>
                            </el-form-item>
                            <el-form-item 
                                label="银行卡号：" 
                                prop="cardNo"
                                :rules="[{required:true,message:'银行卡号不能为空',trigger: 'blur'}]">
                                <el-input v-model="ruleForm.cardNo" placeholder="请输入银行卡号" style="width:300px;"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div v-if="ruleForm.authenticationType == 2">
                        <el-form-item 
                            label="公司名称：" 
                            prop="companyName"
                            :rules="[{required:true,message:'公司名称不能为空',trigger: 'blur'}]">
                            <el-input v-model="ruleForm.companyName" placeholder="请输入公司名称" style="width:500px;"></el-input>
                            <div class="textColor">如有括号，请使用中文格式（）</div>
                        </el-form-item>
                        <el-form-item 
                            label="法定代表人：" 
                            prop="legalName"
                            :rules="[{required:true,message:'法定代表人不能为空',trigger: 'blur'}]">
                            <el-input v-model="ruleForm.legalName" placeholder="请输入法定代表人" style="width:500px;"></el-input>
                        </el-form-item>
                        <el-form-item 
                            label="法人身份证号码：" 
                            prop="legalIds"
                            :rules="[{required:true,message:'法人身份证号码不能为空',trigger: 'blur'}]">
                            <el-input v-model="ruleForm.legalIds" placeholder="请输入法人身份证号码" style="width:500px;"></el-input>
                        </el-form-item>
                        <el-form-item 
                            label="手机号：" 
                            prop="legalPhone"
                            :rules="[{required:true,message:'手机号不能为空',trigger: 'blur'}]">
                            <el-input v-model="ruleForm.legalPhone" placeholder="请输入手机号" style="width:500px;"></el-input>
                        </el-form-item>
                        <el-form-item 
                            label="营业执照号：" 
                            prop="businessLicense"
                            :rules="[{required:true,message:'营业执照号不能为空',trigger: 'blur'}]">
                            <el-input v-model="ruleForm.businessLicense" placeholder="请输入营业执照号" style="width:500px;"></el-input>
                        </el-form-item>
                        <el-form-item 
                            label="组织机构代码：" 
                            prop="organizationCode"
                            :rules="[{required:true,message:'组织机构代码不能为空',trigger: 'blur'}]">
                            <el-input v-model="ruleForm.organizationCode" placeholder="请输入组织机构代码" style="width:500px;"></el-input>
                        </el-form-item>
                        <el-form-item 
                            label="税务登记证：" 
                            prop="taxRegister"
                            :rules="[{required:true,message:'税务登记证不能为空',trigger: 'blur'}]">
                            <el-input v-model="ruleForm.taxRegister" placeholder="请输入税务登记证" style="width:500px;"></el-input>
                        </el-form-item>
                        <el-form-item 
                            label="开户行：" 
                            prop="parentBankName"
                            :rules="[{required:true,message:'开户行不能为空',trigger: 'blur'}]">
                            <el-input v-model="ruleForm.parentBankName" placeholder="请输入开户行" style="width:500px;"></el-input>
                        </el-form-item>
                        <el-form-item 
                            label="开户支行：" 
                            prop="bankName"
                            :rules="[{required:true,message:'开户支行不能为空',trigger: 'blur'}]">
                            <el-input v-model="ruleForm.bankName" placeholder="请输入开户支行" style="width:500px;"></el-input>
                        </el-form-item>
                        <el-form-item 
                            label="对公银行卡号：" 
                            prop="accountNo"
                            :rules="[{required:true,message:'对公银行卡号不能为空',trigger: 'blur'}]">
                            <el-input v-model="ruleForm.accountNo" placeholder="请输入对公银行卡号" style="width:500px;"></el-input>
                        </el-form-item>
                        <el-form-item 
                            label="银行行号：" 
                            prop="unionBank"
                            :rules="[{required:true,message:'银行行号不能为空',trigger: 'blur'}]">
                            <el-input v-model="ruleForm.unionBank" placeholder="请输入银行行号" style="width:500px;"></el-input>
                        </el-form-item>
                    </div>
				</div>
			</el-card>
			
			<div class="footer" v-if="ruleForm.authenticationType == 1 && active == 0">
				<el-button style="width:240px;" @click="changeActive(1)">下一步</el-button>
			</div>
            <div class="footer" v-if="ruleForm.authenticationType == 1 && active == 1">
				<el-button style="width:240px;" @click="changeActive(0)">后退</el-button>
				<el-button
					style="width:240px;"
					type="primary"
					:loading="loading"
                    @click="bindBankCard"
				>提交</el-button>
			</div>
            <div class="footer" v-if="ruleForm.authenticationType == 2">
				<el-button
					style="width:240px;"
					type="primary"
					:loading="loading"
                    @click="companyCertification"
				>提交</el-button>
			</div>
		</el-form>
	</div>
</template>

<script>
import {
    certification,
    companyCertification,
    routingSend,
    bindBankCard
    // applicationTransfer,
    // withdrawApply
} from '@/api/finance'

export default {
	components: {
        
	},
	data () {
		return {
            loading:false,
            active: 0,  //步骤
            ruleForm:{
                //表单数据
                authenticationType:1, //认证类型1=个人，2=企业
                realName:'',    //真实姓名
                phone:'',   //手机号
                identityNo:'',  //身份证号
                verificationCodeType:'',    //验证码
                cardNo:'',  //银行卡号
                accountNo:'',  //企业对公账户
                bankName:'',  //开户行支行名称
                businessLicense:'',  //营业执照号
                companyName:'',  //企业名称 如有括号，用中文格式（）
                legalIds:'',  //法人证件号码
                legalName:'',  //法人姓名
                legalPhone:'',  //法人手机号码
                organizationCode:'',  //组织机构代码
                parentBankName:'',  //开户银行名称
                taxRegister:'',  //税务登记证
                unionBank:'',   //支付行号
            },
            addressList:[], //城市数据
            userId:'',  //用户id
            countDown:60,//倒计时
            countDownText:'获取验证码',   //倒计时显示
		}
	},
	created () {
        
	},
	computed: {
		
	},
	methods: {
        //下一步/后退
		async changeActive(num){
            let _this = this;
            if (num == 1) {
                _this.$refs['ruleForm'].validate(async (valid) => {
                    if (valid) {
                        //只有个人有第一步
                        let data = {
                            identityNo: _this.ruleForm.identityNo,  
                            phone: _this.ruleForm.phone,  
                            realName: _this.ruleForm.realName,  
                            verificationCodeType: _this.ruleForm.verificationCodeType,  
                        }
                        let result = await certification(data);
                        if (result.success) {
                            _this.active = num;
                            _this.userId = result.data.bizUserId;
                        }else{
                            _this.$message({
                                showClose: true,
                                type: 'error',
                                message: result.alertMsg
                            });
                        }
                        
                    }
                });
            }else{
                _this.active = num;
            }
        },
        //获取验证码
        async send(type){
            try {
                let data = {
                    phone:'',  
                    type:type,
                }
                if (type == 2) {
                    //企业
                    data.phone = this.ruleForm.legalPhone;
                }else if (type == 3) {
                    //个人
                    data.phone = this.ruleForm.phone;
                }
                let reg = /^1[3456789]\d{9}$/;
                reg = reg.test(data.phone);
                console.log(reg);
                if (!reg) {
                    this.$message({
                        type: 'error',
                        message: '请输入正确的手机号！'
                    });
                    return;
                }
                if (this.countDown != 60) {
                    return;
                }
                this.countDownStart();
				let result = await routingSend(data);
				if (result.success) {
                    this.$message({
                        showClose: true,
                        type: 'success',
                        message: '获取成功'
                    });
                }else{
                    this.$message({
                        showClose: true,
                        type: 'error',
                        message: result.alertMsg
                    });
                    this.countDown = 0;
                }
			} finally {
				this.loading = false
			}
        },
        //开启倒计时
        countDownStart(){
            let _this = this;
            if (this.countDown > 0) {
                this.countDown--;
                this.countDownText = this.countDown +'秒后可再次获取';
                setTimeout(() => {
                    _this.countDownStart();
                }, 1000);
            }else{
                this.countDown = 60;
                this.countDownText = '再次获取';
            }
        },
        //绑卡
        bindBankCard(){
            let _this = this;
            _this.$refs['ruleForm'].validate(async (valid) => {
                if (valid) {
                    let data = {
                        cardNo: _this.ruleForm.cardNo,  
                        identityNo: _this.ruleForm.identityNo,  
                        name: _this.ruleForm.realName,  
                        phone: _this.ruleForm.phone,  
                        bizUserId: _this.userId,
                        bankName: _this.ruleForm.bankName,
                        parentBankName: _this.ruleForm.parentBankName,
                    }
                    let result = await bindBankCard(data);
                    if (result.success) {
                        _this.$message({
                            showClose: true,
                            type: 'success',
                            message: '保存成功'
                        });
                        _this.back();
                    }else{
                        _this.$message({
                            showClose: true,
                            type: 'error',
                            message: result.alertMsg
                        });
                    }
                }
            });
        },
        //企业认证
        companyCertification(){
            let _this = this;
            _this.$refs['ruleForm'].validate(async (valid) => {
                if (valid) {
                    let data = {
                        "accountNo": _this.ruleForm.accountNo,
                        "bankName": _this.ruleForm.bankName,
                        "businessLicense": _this.ruleForm.businessLicense,
                        "companyName": _this.ruleForm.companyName,
                        "legalIds": _this.ruleForm.legalIds,
                        "legalName": _this.ruleForm.legalName,
                        "legalPhone": _this.ruleForm.legalPhone,
                        "organizationCode": _this.ruleForm.organizationCode,
                        "parentBankName": _this.ruleForm.parentBankName,
                        "taxRegister": _this.ruleForm.taxRegister,
                        "unionBank": _this.ruleForm.unionBank,
                        "userId": _this.userId
                    }
                    let result = await companyCertification(data);
                    if (result.success) {
                        _this.$message({
                            showClose: true,
                            type: 'success',
                            message: '保存成功'
                        });
                        _this.back();
                    }else{
                        _this.$message({
                            showClose: true,
                            type: 'error',
                            message: result.alertMsg
                        });
                    }
                    
                }
            });
        },
        //返回上一页
        back(){
            this.$store.state.tagsView.visitedViews = this.$store.state.tagsView.visitedViews.filter(
                (item) => {
                    return item.path != this.$route.path;
                }
            );
            this.$router.push({
                path: '/finance/collectionAccount/index'
            });
        }
	}
}
</script>

<style lang="less" scoped>
.suppierDetail {
	padding: 10px;
    .clearfix{
        margin-left: 27%;
    }
}

.footer {
	box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
	line-height: 80px;
	background-color: #fff;
	position: fixed;
	bottom: 0px;
	width: 100%;
	text-align: center;
}

.textColor {
    color: #909399;
    font-size: 12px;
}

</style>
